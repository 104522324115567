import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-choose-team-modal',
  templateUrl: './choose-team-modal.component.html',
  styleUrls: ['./choose-team-modal.component.scss']
})
export class ChooseTeamModalComponent implements OnInit {

  @Input() fromGeneral: any;
  @Input() set openTeamsModal(value: boolean) {
    if (value) {
      this.openModal();
    }
  }

  @Output() teamsSelected = new EventEmitter<any>();


  teamsToChoose: any;
  isAllTeams: boolean | undefined;

  teamsForm: FormGroup;

  selectedTeams: any[] = [];

  constructor(private data: DataManipulationService, private fb: FormBuilder) {
    this.teamsToChoose = this.data.allTeams;
    this.teamsForm = this.fb.group({
      teams: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.selectedTeams = new Array(this.teamsToChoose.length).fill(false);
    this.teamsForm.value.teams = [];
    if(this.fromGeneral.fromGeneral && this.fromGeneral.teams.length === 0) {
      this.selectedTeams = new Array(this.teamsToChoose.length).fill(true);
      this.teamsToChoose.forEach((team: any) => {
        this.onChange(team.uuid, true);
      });
      this.isAllTeams = true;
    } else if ((this.fromGeneral.fromGeneral || !this.fromGeneral.fromGeneral) && this.fromGeneral.teams.length != 0) {
      this.isAllTeams = this.teamsToChoose.length === this.fromGeneral.teams.length;
      this.fromGeneral.teams.forEach((selectedTeam:any) => {
        this.teamsToChoose.forEach((team: any, index: number) => {
          if (selectedTeam.uuid === team.uuid) {
            this.selectedTeams[index] = true;
            this.onChange(team.uuid, true);
          }
        });
      });
      
    } else {
      this.isAllTeams = false;
    }
  }

  openModal() {
    const openModal = document.getElementById('chooseTeamsModalButton');
    openModal?.click();
  }

  onChange(teamUuid: string, isChecked: any) {
    const teamsFromArray = <FormArray>this.teamsForm.controls['teams'];

    if (isChecked?.target?.checked || isChecked === true) {
      teamsFromArray.push(new FormControl(teamUuid));
    } else {
      let index = teamsFromArray.controls.findIndex(x => x.value == teamUuid)
      teamsFromArray.removeAt(index);
    }
  }

  closeModal() {
    if(this.teamsForm.value.teams.length === 0) {
      this.teamsSelected.emit('empty');
    } else {
      // this.teamsSelected.emit('');
    }
  }

  assignTeams() {
    const openModal = document.getElementById('closeModal');
    openModal?.click();
    this.teamsSelected.emit({teams: this.teamsForm.value.teams, categoryUuid: this.fromGeneral.categoryUuid});
  }

  selectAllTeams(event: any) {
    if(event.target.checked) {
      this.selectedTeams = new Array(this.teamsToChoose.length).fill(true);
    } else {
      this.selectedTeams = new Array(this.teamsToChoose.length).fill(false);
    }
    if (this.teamsForm.value.teams.length) {
      this.teamsToChoose.forEach((team: any) => {
        this.onChange(team.uuid, false);
      });
    }
    this.teamsToChoose.forEach((team: any) => {
      this.onChange(team.uuid, event.target.checked);
    });
    
  }

}
