<div class="row teams-overview-header">
  <div style="display: flex; justify-content: space-between; padding: 0 2rem;max-width: 150rem; margin: 4rem auto 0">
    <h2 style="font-size: 2.2rem; font-weight: 400">{{'insight_main_title'| translate}}</h2>
    <h4 style="color: #2551BF; cursor:pointer;" [routerLink]="'/team/'+team['uuid']"><i
      class="bi bi-arrow-left ms-2"></i> {{'insights_back_button'| translate}}</h4>
  </div>
</div>
<div *ngIf="gotReport && gotStatements" class="team-analyse-container">
  <div class="matrix-container">
    <div class="d-flex align-items-center" style="margin: .8rem 0 2rem 0;">
      <h3 class="matrix-container-title" style="margin: 0 .5rem .5rem 0;">{{'insights_title'|translate}} |</h3>
      <h3 style="font-size: 2.2rem; color: #2551BF; cursor:pointer;" routerLink="/team/{{team.uuid}}">{{team?.name}}</h3>
    </div>
    
    <!--        <p-dropdown [options]="dropdownOptions" dropdownIcon="bi bi-caret-down-fill" optionLabel="name" ></p-dropdown>-->
    <h3 class="results-period-title">{{'team_period'|translate}} {{teamReportDate.startDate | date}} - {{teamReportDate.endDate | date}}</h3>
    <div class="matrix-indicators-container">
      <div class="matrix-indicator-header">
        <h3 class="matrix-title" [style]="{'font-size':'1.4rem'}">{{'indicators'|translate}}</h3>
        <div class="matrix-sub-category">
          <div class="sub-category"
               [style]="(matrix.categories.Individual) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [src]="matrix.categories.Individual? '../../../assets/svg/individual-dark-blue.svg': '../../../assets/svg/individual.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'.9rem'}">{{'individual'|translate}}</h3>
          </div>
          <div class="sub-category"
               [style]="(matrix.categories.Team) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [src]="matrix.categories.Team? '../../../assets/svg/culture-dark-blue.svg': '../../../assets/svg/culture.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'.9rem'}">{{'insights_team' | translate}}</h3>
          </div>
          <div class="sub-category"
               [style]="(matrix.categories.Leadership) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [src]="matrix.categories.Leadership? '../../../assets/svg/leadership-dark-blue.svg': '../../../assets/svg/leadership.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'.9rem'}">{{'leadership'|translate}}</h3>
          </div>
        </div>
      </div>
      <div class="matrix-indicator-body">
        <div class="matrix-indicators">
          <div class="indicators-category"
               [style]="(matrix.indicators.Performance) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [style]="matrix.indicators.Performance? 'margin-bottom: 2.8rem':''"
              [src]="matrix.indicators.Performance? '../../../assets/svg/performance-dark-blue.svg': '../../../assets/svg/performance.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'-1.1rem'}">{{'team_performance'| translate}}</h3>
          </div>
          <div class="indicators-category"
               [style]="(matrix.indicators.Motivation) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [style]="matrix.indicators.Motivation? 'margin-bottom: 2.8rem':''"
              [src]="matrix.indicators.Motivation? '../../../assets/svg/motivation-dark-blue.svg': '../../../assets/svg/motivation.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'-1.1rem'}">{{'team_motivation'|translate}}</h3>
          </div>
          <div class="indicators-category"
               [style]="(matrix.indicators.Satisfaction) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [style]="matrix.indicators.Satisfaction? 'margin-bottom: 2.8rem':''"
              [src]="matrix.indicators.Satisfaction? '../../../assets/svg/satisfaction-dark-blue.svg': '../../../assets/svg/satisfaction.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'-1.1rem'}">{{'team_satisfaction'| translate}}</h3>
          </div>
          <div class="indicators-category"
               [style]="(matrix.indicators.Stress) ? 'background-color: #EDF9FF; color: #193884; border: none;':''">
            <img
              [style]="matrix.indicators.Stress? 'margin-bottom: 2.8rem':''"
              [src]="matrix.indicators.Stress? '../../../assets/svg/stress-dark-blue.svg': '../../../assets/svg/stress.svg' ">
            <h3 [style]="{'font-size':'1.2rem','margin-top':'-1.1rem'}">{{'team_stress_free'|translate}}</h3>
          </div>
        </div>

        <div class="matrix-indicators-values" *ngIf="!showMatrix && !noMatrix">
          <p-skeleton></p-skeleton>
        </div>
        <div class="matrix-indicators-values" *ngIf="showMatrix && !noMatrix">
          <div *ngFor="let teamIndicator of teamReport['Individual']['indicators']"
               [ngClass]="(matrix.categories[teamReport['Individual']['title']] && matrix.indicators[teamIndicator.indicator.title]) ? 'indicators-percentage-box-active':''"
               class="indicators-percentage-box" [ngStyle]="(teamIndicator.eligibleForResult === 'no') ? {'opacity':'0.5'}:{}"
               (click)="sortMatrix(teamReport['Individual']['title'], teamIndicator.indicator.title)">
            <i *ngIf="teamIndicator.eligibleForResult === 'no'" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark matrix-warning"
               pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_performance' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_satisfaction' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_motivation' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_stress' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
          </div>
          <div *ngFor="let teamIndicator of teamReport['Team']['indicators']"
               [ngClass]="(matrix.categories[teamReport['Team'].title] && matrix.indicators[teamIndicator.indicator.title]) ? 'indicators-percentage-box-active':''"
               class="indicators-percentage-box" [ngStyle]="(teamIndicator.eligibleForResult === 'no') ? {'opacity':'0.5'}:{}"
               (click)="sortMatrix(teamReport['Team']['title'], teamIndicator.indicator.title)">
            <p>{{teamIndicator.length}}</p>
            <i *ngIf="teamIndicator.eligibleForResult === 'no'" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark matrix-warning"
               pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_performance' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_satisfaction' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_motivation' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_stress' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
          </div>
          <div *ngFor="let teamIndicator of teamReport['Leadership']['indicators']"
               [ngClass]="(matrix.categories[teamReport['Leadership']['title']] && matrix.indicators[teamIndicator.indicator.title]) ? 'indicators-percentage-box-active':''"
               class="indicators-percentage-box" [ngStyle]="(teamIndicator.eligibleForResult === 'no') ? {'opacity':'0.5'}:{}"
               (click)="sortMatrix(teamReport['Leadership']['title'], teamIndicator.indicator.title)">
            <i *ngIf="teamIndicator.eligibleForResult === 'no'" class="bi bi-exclamation-circle-fill me-1 text-warning w-dl-questionmark matrix-warning"
               pTooltip="{{'matrix_alert'|translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-score"></i>
            <div [ngClass]="getProgressbarColorClass(teamIndicator.color)"
                 *ngIf="teamIndicator.indicator['title'] === ('insights_action_performance' | translate)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_satisfaction' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_motivation' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
            <div *ngIf="teamIndicator.indicator['title'] === ('insights_action_stress' | translate)"
                 [ngClass]="getProgressbarColorClass(teamIndicator.color)" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">{{teamIndicator.percentage}}<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="teamIndicator.percentage" [showValue]="false"></p-progressBar>
            </div>
          </div>
        </div>
        <div class="matrix-indicators-values" *ngIf="noMatrix">
          <div *ngFor="let _ of [].constructor(4)"
               class="indicators-percentage-box">
            <div [ngClass]="getProgressbarColorClass('')" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">0<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="0" [showValue]="false"></p-progressBar>
            </div>
          </div>
          <div *ngFor="let _ of [].constructor(4)"
               class="indicators-percentage-box">
            <div [ngClass]="getProgressbarColorClass('')" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">0<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="0" [showValue]="false"></p-progressBar>
            </div>
          </div>
          <div *ngFor="let _ of [].constructor(4)"
               class="indicators-percentage-box">
            <div [ngClass]="getProgressbarColorClass('')" class="team-report-percentage-value">
              <h3 [style]="{'font-size':'1.7rem'}">0<span
                [style]="{'font-size':'1.3rem'}">%</span></h3>
              <p-progressBar [value]="0" [showValue]="false"></p-progressBar>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="matrix-indicators-message">
      <h4>{{'insights_note'| translate}}</h4>
    </div>
  </div>
  <div class="statements-and-responses">
    <div class="statements-and-responses-header">
      <div class="statements-and-responses-title-container">
        <h3 class="statements-and-responses-title">{{'insights_title2'|translate}}<span
          *ngIf="statements.length > 0">({{statements.length}})</span></h3>
        <h3 *ngIf="matrixSelected" style="margin-left: 3rem; color: #A6ABB5; font-size: 2.2rem;">
          {{matrix.categories.Individual ? ('insights_action_individual' | translate) : 
            matrix.categories.Team ? ('insights_action_team' | translate) : 
            matrix.categories.Leadership ? ('insights_action_leadership' | translate): ''}} x
          {{matrix.indicators.Performance ? ('insights_action_performance' | translate) : 
          matrix.indicators.Motivation ? ('insights_action_motivation' | translate) : 
          matrix.indicators.Satisfaction || matrix.indicators.Tilfredshed ? ('insights_action_satisfaction' | translate): 
          (matrix.indicators.Stress || matrix.indicators['Stress-fri']) ? ('insights_action_stress' | translate):''}}</h3>
      </div>
      <div class="statements-filters">
        <p-dropdown [options]="dropdownOptions" (onChange)="getStatementsSelector($event.value)"
                    dropdownIcon="bi bi-caret-down-fill" optionLabel="name"></p-dropdown>
        <div class="statements-filter-buttons">
          <button pButton type="button" label="{{'insights_highest'|translate}}" class="statements-filter-button" (click)="sortBest(true)"
                  [style]="bestButton?'background-color:#193884; color: white; box-shadow: none;':''"></button>
          <button style="margin: 0 .5rem" pButton type="button" label="{{'insights_lowest'| translate}}" class="statements-filter-button"
                  (click)="sortBest(false)" [style]="worstButton?'background-color:#193884; color: white; box-shadow: none;':''"></button>
          <button pButton type="button" label="{{'insights_acted_upon'| translate}}" class="statements-filter-button"
                  (click)="actedUponSort()" [style]="actedUpon?'background-color:#193884; color: white; box-shadow: none;':''"></button>
        </div>
      </div>
    </div>
    <div class="statements-and-responses-body">
      <div *ngIf="statements.length > 0 && gotStatements">
        <div *ngFor="let statement of statements; let x = index" class="statements-values" [ngClass]="openedAccordionIndex === x ? 'header-color-'+statement.colorName: statement.hasAssignee > 0 ? 'statements-with-assignee':''">
          <p-accordion collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down" (click)="clickHeader(x)" (onOpen)="onOpenAccordion($event, x)" (onClose)="onCloseAccordion()">
            <p-accordionTab [(selected)]="showSelectedTab[x]">
              <ng-template pTemplate="header">
                <img *ngIf="openedAccordionIndex === x" class="statement-icon" src="../../../assets/svg/opened-statement-{{statement?.colorName}}.svg">
                <img *ngIf="!(openedAccordionIndex === x)" class="statement-icon" src="../../../assets/svg/statement-icon-{{statement?.colorName}}.svg">
                <h3 [style]="{'font-size':'1.6rem', 'margin':'0', 'max-width':'70rem'}">{{statement.question.title}}</h3>
                <div *ngIf="statement.hasAssignee > 0" class="statement-tag">
                  <p>{{statement.hasAssignee}} {{statement.hasAssignee === 1 ? 'action' : 'actions' }} </p>
                  <img style="margin-left: .4rem;" src="../../../assets/svg/action-active.svg">
                </div>
              </ng-template>
              <ng-template pTemplate="content">
<!--                <div *ngIf="statement?.colorName !== 'green'">-->
                <div >
                  <app-response-distribution 
                    *ngIf="(statement.question.responseDistribution.eligibleForResult || statement.question.responseDistribution.eligibleForShowing) && statement?.colorName !== 'default'" 
                    [responseDistributionObs]="responseDistribution"></app-response-distribution>
                  <div *ngIf="statement?.colorName !== 'default'" class="d-flex flex-row align-items-center" 
                    [style]="statement.question.responseDistribution.eligibleForResult || statement.question.responseDistribution.eligibleForShowing? {'margin-top': '3.66rem'}:{}">
                    <h3 [style]="{'font-size':'2rem','color':'#000000', 'margin':'1rem 0 .8rem 0'}">
                      {{(!statement.question.gotTasks ? 'insights_statement_no_actions': 'insights_statement_actions') | translate}}</h3>
                    <button pButton type="button" label="{{'insights_statement_make_my_own' | translate}}" class="solution-assigned"
                            style="margin: 0 0 0 auto;" data-eyelet="eyelet-insights-custom-action"
                            (click)="makeOwnToggle(x)"></button>
                  </div>
                  <!-- <p-skeleton *ngIf="!statement.question.gotTasks && statement.question.noTasks" width="100%" height="150px"></p-skeleton> -->
                  <h3 *ngIf="statement.question.gotTasks && statement.question.tasks.length === 0">You
                    don't have any team insights yet. Please wait for the first loop to be completed.</h3>
                  <h4 style="margin-bottom: 0;" *ngIf="statement?.colorName === 'default'">{{'insights_blue_statement' | translate}}</h4>
                  <div *ngIf="statement?.colorName !== 'default'">
                    <!-- <span [style]="{'font-size':'1rem','color':'#2551BF'}">{{"insights_statement_make_own"| translate}}</span> -->
                    <div id="makeOwn{{x}}" [ngClass]="!statement.question.gotTasks ? 'toggle-solution':''" class="make-own-solution">
                      <div>
                        <div class="own-task-form">
                          <div style="display: flex; align-items: center;border: 1px solid #E4EDFE;border-radius: .7rem; margin: 1rem 0 .9rem 0;">
                            <!-- <i class="pi pi-bolt"></i> -->
                            <img style="margin: 0 0 .2rem 1rem" src="../../../assets/svg/action-inactive.svg">
                            <input class="task-title-input" id="ownActionTitle"
                                   [ngClass]="challengesService.newStatementsActions[statement.question.uuid].titleError ? 'invalid-input': ''"
                                   [(ngModel)]="challengesService.newStatementsActions[statement.question.uuid].actionTitle"
                                   placeholder="{{'insights_statement_make_own' | translate}}">
                          </div>
                          <textarea class="task-description-input"
                                    [ngClass]="challengesService.newStatementsActions[statement.question.uuid].descriptionError ? 'invalid-input': ''"
                                    [(ngModel)]="challengesService.newStatementsActions[statement.question.uuid].actionDescription"
                                    placeholder="{{'insights_own_action_description' | translate}}" type="text"></textarea>
                        </div>

                        <div class="possible-solutions-actions">
                          <div class="d-flex ">
                            <button *ngIf="data.teamLeaders.length === 1" pButton type="button"
                                    label="{{'assign_to_me'|translate}}"
                                    [class]="'solution-assign-to'"
                                    (click)="createAction(statement.question.uuid, data.teamLeaders[0])">
                              <!--                              <img class="user-avatar single-leader" tooltipPosition="bottom"-->
                              <!--                                   tooltipStyleClass="tooltip-style"-->
                              <!--                                   pTooltip="{{ data.teamLeaders[0].user.detail.displayName | getDisplayName: data.teamLeaders[0].user.detail.firstname: data.teamLeaders[0].user.detail.lastname: data.teamLeaders[0].user.email }}"-->
                              <!--                                   src="{{(data.teamLeaders[0].user.avatar !== null && data.teamLeaders[0].user.avatar.path !== null) ? this.data.baseUrl + data.teamLeaders[0].user.avatar.path: '../../../assets/svg/user-icon.svg'}}">-->
                            </button>
                            <div class="assign-action-dropdown" style="margin: 1rem 0 0 0;">
                              <p-dropdown *ngIf="data.teamLeaders.length > 1" [options]="data.teamLeaders" [(ngModel)]="selectedTeamLeaderForAction" class="team-type-dropdown" optionLabel="name" placeholder="Assign to" dropdownIcon="bi bi-chevron-down">
                                <ng-template let-selectedLeader pTemplate="selectedItem">
                                    <div class="sort-item sort-item-value" *ngIf="selectedTeamLeaderForAction">
                                      <span>{{ selectedLeader.user.detail.displayName | getDisplayName: selectedLeader.user.detail.firstname: selectedLeader.user.detail.lastname: selectedLeader.user.email }}</span>
                                      <img class="action-assigned-user-icon"
                                        src="{{(selectedLeader.user.avatar !== null && selectedLeader.user.avatar.path !== null) ? this.data.baseUrl + selectedLeader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                                      </div>
                                </ng-template>
                                <ng-template let-leader pTemplate="item">
                                  <div (click)="createAction(statement.question.uuid, leader)">
                                    <span>{{ leader.user.detail.displayName | getDisplayName: leader.user.detail.firstname: leader.user.detail.lastname: leader.user.email }}</span>
                                    <img class="action-assigned-user-icon"
                                      src="{{(leader.user.avatar !== null && leader.user.avatar.path !== null) ? this.data.baseUrl + leader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                                  </div>
                                </ng-template>
                              </p-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let possibleSolution of statement.question.tasks ; let i = index">
                      <div *ngIf="possibleSolution.task.status !== 'useless'" class="statement-possible-solutions"
                           data-eyelet=”eyelet-insights-actions”>
                  <h3 class="possible-solution-title">
                    <img style="margin-right: .6rem;" [src]="'../../../assets/svg/action-' + (possibleSolution.task.assignedTasks.length ? 'active.svg':'inactive.svg')">
                      {{possibleSolution.task.title}}</h3>
                        <span #content [ngClass]="showMoreText[x][i] ? 'show-more-text':'show-less-text'"
                              class="possible-solution-description" style="font-size: 1.2rem;"
                              [innerHTML]="possibleSolution.task.displayDescription"></span>
                        <div *ngIf="content.scrollHeight > 50" class="d-flex align-items-center">
                          <i [ngClass]="!showMoreText[x][i] ? 'bi bi-chevron-down':'bi bi-chevron-up'" style="margin: .4rem .4rem 0 0;"></i>
                          <button class="show-more-button"
                            (click)="showMoreText[x][i] = !showMoreText[x][i]">{{ !showMoreText[x][i] ? 'Show more' : 'Show less' }}</button>
                        </div>
                        <div class="possible-solutions-actions">
                          <!--                          <button pButton type="button"-->
                          <!--                                  label="{{possibleSolution.frequency > 0? 'Assigned':'Assign to +'}}"-->
                          <!--                                  [ngClass]="possibleSolution.frequency > 0? 'solution-assigned' : 'solution-assign-to'"-->
                          <!--                                  *ngIf="data.teamLeaders.length === 1"-->
                          <!--                                  (click)="assignAction(possibleSolution.task, statement.question, data.teamLeaders[0])">-->
                          <!--                            <img class="user-avatar single-leader" tooltipPosition="bottom"-->
                          <!--                                 tooltipStyleClass="tooltip-style"-->
                          <!--                                 pTooltip="{{ data.teamLeaders[0].user.detail.displayName | getDisplayName: data.teamLeaders[0].user.detail.firstname: data.teamLeaders[0].user.detail.lastname: data.teamLeaders[0].user.email }}"-->
                          <!--                                 src="{{(data.teamLeaders[0].user.avatar !== null && data.teamLeaders[0].user.avatar.path !== null) ? this.data.baseUrl + data.teamLeaders[0].user.avatar.path: '../../../assets/svg/user-icon.svg'}}">-->
                          <!--                          </button>-->
                          <!--                          <button pButton type="button"-->
                          <!--                                  label="{{possibleSolution.frequency > 0? 'Assigned':'Assign to +'}}"-->
                          <!--                                  [ngClass]="possibleSolution.frequency > 0 ?'solution-assigned' : 'solution-assign-to'"-->
                          <!--                                  *ngIf="data.teamLeaders.length > 1" [matMenuTriggerFor]="teamsMenu"></button>-->
                          <!--                          -->
                          <div class="assign-action-dropdown" *ngIf="data.teamLeaders.length && possibleSolution.task.assignedTasks.length === 0">
                            <p-dropdown [options]="data.teamLeaders" [(ngModel)]="selectedTeamLeaderForAction" class="team-type-dropdown" optionLabel="name" placeholder="Assign to" dropdownIcon="bi bi-chevron-down">
                              <ng-template let-selectedLeader pTemplate="selectedItem">
                                  <div class="sort-item sort-item-value" *ngIf="selectedTeamLeaderForAction">
                                    <span>{{ selectedLeader.user.detail.displayName | getDisplayName: selectedLeader.user.detail.firstname: selectedLeader.user.detail.lastname: selectedLeader.user.email }}</span>
                                    <img class="action-assigned-user-icon"
                                      src="{{(selectedLeader.user.avatar !== null && selectedLeader.user.avatar.path !== null) ? this.data.baseUrl + selectedLeader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                                    </div>
                              </ng-template>
                              <ng-template let-leader pTemplate="item">
                                <div (click)="!data.showDemo ? assignAction(possibleSolution.task, statement, leader):''">
                                  <span>{{ leader.user.detail.displayName | getDisplayName: leader.user.detail.firstname: leader.user.detail.lastname: leader.user.email }}</span>
                                  <img class="action-assigned-user-icon"
                                    src="{{(leader.user.avatar !== null && leader.user.avatar.path !== null) ? this.data.baseUrl + leader.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">
                                </div>
                              </ng-template>
                            </p-dropdown>
                          </div>
                          <div *ngIf="possibleSolution.task.assignedTasks.length && possibleSolution.frequency > 0" class="assigned-container">
                              <img class="assigned-task-avatar" src="{{possibleSolution.task.assignedTasks[0].assignee.avatar != null ? this.data.baseUrl + possibleSolution.task.assignedTasks[0].assignee.avatar.path : '../../../assets/svg/user-icon.svg'}}">
                              <div class="d-flex flex-column" style="margin-left: .845rem;">
                                <span style="font-size: 1.2rem; color: #656565;">{{'assigned_to' | translate}}</span>
                                <span style="font-size: 1.2rem; color: #000000; font-weight: 500;">{{ possibleSolution.task.assignedTasks[0].assignee.detail.displayName | getDisplayName: possibleSolution.task.assignedTasks[0].assignee.detail.firstname: possibleSolution.task.assignedTasks[0].assignee.detail.lastname: possibleSolution.task.assignedTasks[0].assignee.email }}</span>
                              </div>
                          </div>
                          <!-- <div>
                            <button pButton type="button" style="font-size: 1.2rem"
                                    label="{{'pick_action'| translate}}"
                                    [class]="'solution-assign-to'"
                                    (click)="assignAction(possibleSolution.task, statement.question, data.teamLeaders[0])">
                            </button>
                            <button type="button" class="show-more-button" style=" padding: 10px; width: auto;"
                                    *ngIf="data.teamLeaders.length > 1" [matMenuTriggerFor]="teamsMenu">{{'assign_others'|translate}}
                            </button>
                          </div>
                          <mat-menu #teamsMenu xPosition="before">
                            <button mat-menu-item *ngFor="let member of data.teamLeaders"
                                    (click)="assignAction(possibleSolution.task, statement.question, member)">
                              <img class="user-avatar"
                                   src="{{(member.user.avatar !== null && member.user.avatar.path !== null) ? this.data.baseUrl + member.user.avatar.path: '../../../assets/svg/user-icon.svg'}}">

                              <span
                                class="teams-menu-item">{{ member.user.detail.displayName | getDisplayName: member.user.detail.firstname: member.user.detail.lastname: member.user.email }}</span>
                            </button>
                          </mat-menu> -->
                          <span class="solution-not-relevant" style="margin-left: 3.2rem;" (click)="notRelevant(possibleSolution, statement)">
                            <i class="bi bi-x" style="margin-right: .6rem;"></i>{{'not_relevant'| translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <!--      <button pButton *ngIf="statements.length > 0 && gotStatements"-->
      <!--                      type="button"-->
      <!--                      label="See more"-->
      <!--                      icon="bi bi-arrow-down-short"-->
      <!--                      class="see-more-statements"></button>-->
      <div class="d-flex justify-content-center" *ngIf="statements.length === 0 && gotStatements">
        <h3 style="font-size: 1.6rem;margin: 1.4rem 0 auto 0;">{{'insights_no_statements'| translate}}</h3>
      </div>
    </div>
  </div>
</div>
