import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-special-categories',
  templateUrl: './special-categories.component.html',
  styleUrls: ['./special-categories.component.scss']
})
export class SpecialCategoriesComponent implements OnInit {

  @Input() specialCategories: any;
  @Input() specialStatements: any;
  @Input() selectedTeamsForModal: any;

  @Output() specialCategoriesEvent = new EventEmitter<any>();
  @Output() getStatementsEvent = new EventEmitter<any>();
  @Output() getCategoryTeams = new EventEmitter<any>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();

  openTeamModal: Subject<any> = new Subject<any>();

  specialCategoriesForm: FormGroup;

  specCategories: any;
  categoryStatements: any;
  defaultCategory: any;
  defaultCategoryTitle: string = '';
  teamsToSelectForModal: any;
  
  showTeamModal: boolean = false;
  showSpecialCategories: boolean = false;
  defaultCategoryStart: boolean = true;
  
  selectedTeams: any[] = [];
  selectedCategory: any[] = [];
  statementsExtraQuestions: any[] = [];
  processExtraQuestions: any[] = [];
  categoryFromTeams: any[] = [];

  generalCategoriesMock = [
    {
      title: 'Offensive Actions',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'General satisfaction',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'Productivity and workload',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'Immediate leader',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    }
  ];
  defaultStatementContainer = [{
    question: {
      title: this.translate.instant('custom_poll_gen_categ_prew_statements')
    }
  }];

  constructor(private fb: FormBuilder, private ref: ChangeDetectorRef, private translate: TranslateService) {
    this.specialCategoriesForm = this.fb.group({
      category: this.fb.array([])
    });
  }

   ngOnInit(): void {
    this.processSpecialCategories();
    this.ref.detectChanges();
    this.specialStatements?.subscribe((res: any) => {
      this.categoryStatements = res.statements;
      this.processStatementsExtraQuestions(this.categoryStatements);
    });
  }

  processSpecialCategories() {
    this.specCategories = this.specialCategories[0]?.categories;
    this.selectedCategory = new Array(this.specCategories.length).fill(false);
    this.specialCategories[0].selectedCategories.subscribe((data:any) => {
      if(data.categories.length !== 0) {
        data.categories.forEach((category:any) => {
          this.specCategories.forEach((categ: any, index: number) => {
            if (category.uuid === categ.category.uuid) {
              this.selectedCategory[index] = true;
              this.onChange(categ.uuid, true);
            }
          });
        });
      }
    });

    this.defaultCategoryTitle = this.translate.instant('custom_poll_gen_categ_prew');
    this.categoryStatements = this.defaultStatementContainer;
    this.showSpecialCategories = true;
  }

  processStatementsExtraQuestions(statements: any) {
    statements.forEach((state: any, index: number) => {
      this.processExtraQuestion(state.question);
      const extraQuestions = this.removeDuplicates(this.processExtraQuestions, 'title');
      this.statementsExtraQuestions.push(extraQuestions);
      this.processExtraQuestions = [];
    });
  }

  removeDuplicates(myArray: any, prop: any) {
    return myArray.filter((obj: { [x: string]: any; }, pos: any, arr: any[]) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  processExtraQuestion(extraQuestion: any): any {
    if (extraQuestion.extraQuestion) {
      extraQuestion.extraQuestion.extra = true;
      this.processExtraQuestions.push(extraQuestion.extraQuestion);
      return this.processExtraQuestion(extraQuestion.extraQuestion);
    } else if (extraQuestion.responses.length) {
      extraQuestion.responses.forEach((response: any) => {
        if (response.extraQuestion) {
          response.extraQuestion.extra = true;
          this.processExtraQuestions.push(response.extraQuestion);
          return this.processExtraQuestion(response.extraQuestion);
        }
      });
    }
  }

  setCategoryStatements(index: number, category: any) {
    if (this.defaultCategory !== index) {
      this.getStatementsEvent.emit({categoryUuid : category.category.uuid, index: index});
    }
    this.defaultCategory = index;
    this.defaultCategoryStart = false;
    this.defaultCategoryTitle = category?.category.title + ' ' + this.translate.instant('custom_poll_gen_categ_prew_state');
  }

  onChange(category: string, isChecked: any) {
    const categoryFromArray = <FormArray>this.specialCategoriesForm.controls['category'];

    if (isChecked?.target?.checked || isChecked === true) {
      categoryFromArray.push(new FormControl(category));
    } else {
      let index = categoryFromArray.controls.findIndex(x => x.value == category);
      categoryFromArray.removeAt(index);
    }
  }

  openTeamsModal(event: any, category: any) {
    if (event.target.checked) {
      this.getCategoryTeams.emit({customPollUuid: this.specialCategories[0].pollUuid, categoryUuid: category.category.uuid});
      this.selectedTeamsForModal.subscribe((data: any) => {
        this.teamsToSelectForModal = {fromGeneral: false, teams: data, categoryUuid: category.uuid};
        this.showTeamModal = true;
      });
    }
  }

  setSelectedTeams(teams: any) {
    if (teams !== 'empty') {
      if (this.categoryFromTeams.length) {
        let index = this.categoryFromTeams.findIndex(x => x.categoryUuid == teams.categoryUuid);
        if (index !== -1) {
          this.categoryFromTeams.splice(index, 1);
        }
        this.categoryFromTeams.push(teams);
      } else {
        this.categoryFromTeams.push(teams);
      }
      this.selectedTeams = [...teams.teams];
    }
    this.showTeamModal = false;
  }

  nextStep() {
    this.specialCategoriesEvent.emit({teams: this.categoryFromTeams, customPollUuid: this.specialCategories[0].pollUuid});
    this.categoryFromTeams = [];
  }

  back() {
    this.stepBack.emit({step: 1, uuid: this.specialCategories[0].pollUuid});
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

  onOpenAccordion(event: any, index: number) {
    
  }

}
