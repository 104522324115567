import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-review-custom-poll',
  templateUrl: './review-custom-poll.component.html',
  styleUrls: ['./review-custom-poll.component.scss']
})
export class ReviewCustomPollComponent implements OnInit {
  @Input() set reviewCustomPollTeams(value: any) {
    this.setSummaryData(value);
  }

  @Output() backEvent = new EventEmitter<any>();
  @Output() endReview = new EventEmitter<any>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();

  categoriesSubscription: any;
  defaultTeam: any;   
  pollUuid: any;
  pollDate: any;

  selectedCategories: any[] = [];
  teamsAndCategories: any[] = [];
  teamsWithSpecialCategories: any[] = [];

  teamSelected: boolean = false;

  constructor(public data: DataManipulationService) {}
  
  ngOnInit(): void {
    
  }
  setSummaryData(customPoll: any) {
    this.teamsAndCategories = customPoll.categories;
    this.pollUuid = customPoll.pollUuid;
    this.checkForSpecialCategories();
    this.selectTeam(0);
    this.data.hideLoading();
  }

  selectTeam(index: number) {
    this.defaultTeam = index;
    this.selectedCategories = this.teamsAndCategories[index]?.customPollTeamCategories;
    this.teamSelected = true;
  }

  checkForSpecialCategories() {
    this.teamsAndCategories.forEach((team: any, index: number) => {
      let hasSpecials;
      team.customPollTeamCategories.forEach((category: any) => {
        if(category.category.type === 'special') {
          hasSpecials = true;
        }
      });
      this.teamsWithSpecialCategories.push(hasSpecials);
    });
  }

  endReviewPage() {
    this.endReview.emit(this.pollUuid);
  }

  back() {
    this.teamsAndCategories = [];
    this.teamsWithSpecialCategories = [];
    this.teamSelected = false;
    this.stepBack.emit({step: 2, uuid: this.pollUuid});
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

}
