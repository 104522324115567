import { formatDate } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Tooltip } from 'chart.js';
import { Observable } from 'rxjs';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-onboarding-categories',
  templateUrl: './onboarding-categories.component.html',
  styleUrls: ['./onboarding-categories.component.scss']
})
export class OnboardingCategoriesComponent implements OnInit {

  @HostListener('document:click', ['$event']) onDocumentClick() {
      this.showStartDateContainer = false;
  }

  @Input() set reportingCategories(value: any) {
    if (this.teamFilterTimer) {
      setTimeout(() => {
        this.checkinCategories = value;
        this.getCategoryScoreColor();
      }, 1500);
    } else {
      this.checkinCategories = value;
      this.getCategoryScoreColor();
    }
  }
  
  @Input() set checkinStatements(value: any) {
    this.categoriesStatements = value;
    this.setStatementTab();
    this.statementResponses = [];
    this.getCategoryStatementScoreColor();
  }

  @Input() set stateResponses(value: any | undefined) {
    this.statementResponses = [];
    if (value !== undefined) {
      if (value.type === 'scaled') {
        this.getQuestionDistributionValues(value.responses);
      } else {
        this.statementResponses = value.responses._embedded.responses;
        this.statementResponseCount = value.responses._total_items;
        this.statementResponsePage = value.responses._page_count;
      }
    }
  }

  @Input() set reportingTeamsForFilter(value: any) {
    if (value.selectedTeam !== null && value.selectedTeam !== undefined) {
      this.sortByTeam = value.selectedTeam;
      this.defaultSelectedTeam.push(value.selectedTeam.uuid);
    }
    this.userTeams = value.teams;
  }

  @Input() set usersForFilter(value: any) {
    this.filterUsersForAssignee(value);
  }
  
  @Output() checkinStatementEvent = new EventEmitter<any>();
  @Output() statementResponsesEvent = new EventEmitter<any>();
  @Output() changedTeamEvent = new EventEmitter<any>();
  @Output() selectedUser = new EventEmitter<any>();
  @Output() selectedDateRange = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter<any>();

  checkinCategories: any[] = [];
  categoriesStatements: any[] = [];
  statementResponses: any[] = [];
  showSelectedTabState: any[] = [];
  defaultSelectedTeam: any[] = [];
  userTeams: any[] = [];
  usersForFilterDropdown: any[] = [];

  scoreColorInterval: {color: string, min:number, max:number}[] = [
    {color: 'red', min: 0, max: 39},
    {color: 'orange', min: 40, max: 59},
    {color: 'yellow', min: 60, max: 79},
    {color: 'green', min: 80, max: 100},
  ];
  
  previousShowSelectedTabCategIndex: any;
  showSelectedTabCateg: any;
  previousShowSelectedTabStateIndex: any;
  responseDistribution: any;
  showedOpenQsResponse: any;
  sortByTeam: any = null;
  userFilter: any;
  rangeDates: Date[] = [];

  hasCategoryStatements: boolean = false;
  showResponseModal: boolean = false;
  teamFilterTimer: boolean = false;
  showStartDateContainer: boolean = false;
  sumbitedDate: boolean = false;

  statementResponseCount: number = 0;
  statementResponsePage: number = 0;

  chartData: any[] = [];

  chartOptions = {
    plugins: {
      tooltip: {
        enabled: false
      },
      
    },
    elements : {
      arc : {
        borderColor: '#E6E6E6',
        borderWidth: 3,
      }
    }
  }
  
  
  constructor(public data: DataManipulationService) { }
  
  ngOnInit(): void {
    this.showSelectedTabCateg = new Array(this.checkinCategories.length).fill(false);
  }

  getCategoryScoreColor() {
    let checkinScore = 0;
    this.checkinCategories.forEach((checkin: any, index: number) => {
      checkinScore = checkin.collection?.collectionScore;
      if (checkin.users !== 0 && checkinScore) {
        this.scoreColorInterval.forEach((scoreInterval: any) => {
          if (checkinScore >= scoreInterval.min && checkinScore <= scoreInterval.max) {
            checkin.color = scoreInterval.color;
            this.chartData.push({
              datasets: [{
                data: [checkinScore,(100 - checkinScore)],
                backgroundColor: [
                  //@ts-ignore
                  this.data.colors[''+checkin.color], "#E6E6E6"
                ],
              }]
            });
          }
        });
      } else {
        checkin.color = 'blue';
        this.chartData.push({
          datasets: [{
            data: [100,0],
            backgroundColor: [
              "#E6E6E6"
            ],
          }]
        });
      }
    });
  }

  filterUsersForAssignee(membersToAssign: any) {
    this.usersForFilterDropdown = 
      membersToAssign
      .filter((member: any) => (member.status === 'active'))
      .map((member:any) => {
        return {name: member.detail.displayName ? member.detail.displayName : 
                      member.detail.firstname ? member.detail.firstname + member.detail.lastname : member.email,
                uuid: member.uuid, avatar: member.avatar}});
    // this.usersForFilterDropdown.forEach((member:any, index:number) => {
    //   if (member.uuid === this.currentUser.uuid) {
    //     this.membersForSearch.splice(index, 1);
    //     this.membersForSearch.unshift(member);
    //   }
    // });
  }

  setStatementTab() {
    this.hasCategoryStatements = true;
    this.showSelectedTabState = new Array(this.categoriesStatements.length).fill(false);
  }

  getCategoryStatementScoreColor() {
    let statementScore = 0;
    this.categoriesStatements.forEach((statement: any) => {
      if (statement.question.eligibleForScore) {
        statementScore = statement.question.score;
        this.scoreColorInterval.forEach((scoreInterval: any) => {
          if (statementScore >= scoreInterval.min && statementScore <= scoreInterval.max) {
            statement.question.color = scoreInterval.color;
          }
        });
      } else {
        statement.question.color = 'default';
      }
    });
  }

  getQuestionDistributionValues(value: any) {
    if (value) {
      value.onInsight = true;
      value.eligibleForResult = true;
      this.responseDistribution = new Observable(subcriber => {
        subcriber.next(value);
      });
    }
  }

  openResponsePreviewModal(response: any) {
    this.showedOpenQsResponse = response;
    this.showResponseModal = true;
    const openModal = document.getElementById('actionModalButton');
    openModal?.click();
  }

  changePage(page: any, statementType: any, statementUuid: any) {
    this.statementResponses = [];
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: this.defaultSelectedTeam, page: page.page + 1, limit: 6});
  }

  emitStatementResponses(statementType: any, statementUuid: any, index: number) {
    if (this.previousShowSelectedTabStateIndex === undefined) {
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    } else {
      this.showSelectedTabState[this.previousShowSelectedTabStateIndex] = false;
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    }
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: this.defaultSelectedTeam, page: 1, limit: 6});
  }

  emitCategoryStatements(categoryUuid: any, index: number) {
    this.categoriesStatements = [];
    if (this.previousShowSelectedTabCategIndex === undefined) {
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    } else {
      this.showSelectedTabCateg[this.previousShowSelectedTabCategIndex] = false;
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    }
    this.hasCategoryStatements = false;
    this.checkinStatementEvent.emit(categoryUuid);
  }

  clearStatements() {
    this.statementResponses = [];
  }

  selectTeamsForQuestions(event: any) {
    this.defaultSelectedTeam = [];
    this.usersForFilterDropdown = [];
    this.userFilter = null;
    this.rangeDates = [];
    this.teamFilterTimer = true;
    if (event.value && event.value.uuid !== '') {
      this.defaultSelectedTeam.push(event.value.uuid);
    }
    this.changedTeamEvent.emit({changedTeamUuid: event.value !== null ? event.value?.uuid: '', isNonMembers: event.value !== null ? event.value.nonMembers : false});
    this.closeAllTabs();
  }

  closeAllTabs() {
    if (this.previousShowSelectedTabStateIndex !== undefined || this.previousShowSelectedTabCategIndex !== undefined) {
      this.showSelectedTabState[this.previousShowSelectedTabStateIndex] = false;
      this.showSelectedTabCateg[this.previousShowSelectedTabCategIndex] = false;
    }
  }

  setFilterForUser(user: any) {
    if (user.value) {
      this.selectedUser.emit(user.value.uuid);
    } else {
      this.selectedUser.emit(user.value);
    }
    this.closeAllTabs();
  }

  setDateRange() {
    this.selectedDateRange.emit(this.rangeDates.length? {startDate: formatDate(this.rangeDates[0],'dd.MM.yyyy', 'en-US'), endDate:formatDate(this.rangeDates[1],'dd.MM.yyyy', 'en-US')} : null);
    this.closeAllTabs();
  }

  clearDateRange() {
    this.rangeDates = [];
    this.sumbitedDate = false;
    this.selectedDateRange.emit(null);
    this.closeAllTabs();
  }

  clearFilters() {
    this.data.showLoading();
    this.rangeDates = [];
    this.sumbitedDate = false;
    this.userFilter = null;
    this.sortByTeam = null;
    this.resetFilters.emit();
    this.closeAllTabs();
  }

  showStartDate(event: any) {
    event?.stopPropagation();
    this.showStartDateContainer = true;
  }

  submitDateChanges() {
    this.hideStartDate();
    if (this.rangeDates.length) {
      this.sumbitedDate = true;
      this.setDateRange();
    }
  }

  hideStartDate() {
    event?.stopPropagation();
    this.showStartDateContainer = false;
  }

  cancelDateOptions() {
    event?.stopPropagation();
    this.clearDateOptions();
    this.showStartDateContainer = false;
  }

  clearDateOptions() {
    this.rangeDates = [];
    this.sumbitedDate = false;
  }
}
